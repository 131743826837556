import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import type { Address } from '@wilm/common';
import { CartAddressType } from '@wilm/common';
import { validateAddressField } from '@wilm/shared-backend/commerce-commercetools/validation/field';
import type { Value as PhoneNumberValue } from 'react-phone-number-input';
import Button from 'components/commercetools-ui/atoms/button';
import Checkbox from 'components/commercetools-ui/atoms/checkbox';
import Dropdown from 'components/commercetools-ui/atoms/dropdown';
import Modal from 'components/commercetools-ui/atoms/modal';
import { useFormat } from 'helpers/hooks/useFormat';
import useProcessing from 'helpers/hooks/useProcessing';
import scrollToError from 'helpers/utils/scrollToError';
import { useCountries } from 'providers/countries';
import { useAccount, useCart } from 'frontastic';
import type { CartDetails } from 'frontastic/hooks/useCart/types';
import { useLoqate } from '../../../../../../context/loqate';
import type { States } from '../../../../../../helpers/utils/mapStates';
import mapStates from '../../../../../../helpers/utils/mapStates';
import AddressForm from '../steps/sections/addresses/components/address-form';
import type { Fields } from '../steps/sections/addresses/components/address-form/types';
import useMappers from '../steps/sections/addresses/hooks/useMappers';
import type { FieldErrors } from '../steps/sections/addresses/types';

declare global {
    interface Window {
        pca: any;
    }
}

interface LoqateAddress {
    Line1?: string;
    Line2?: string;
    PostalCode?: string;
    City?: string;
    CountryIso2?: string;
    Province?: string;
}

interface AddressModalProps {
    address?: Address;
    isOpen: boolean;
    closeModal: () => void;
    setSameAsBillingAddress?: (value: boolean) => void;
}

const AddressModal: React.FC<AddressModalProps> = ({ address, isOpen, closeModal, setSameAsBillingAddress }) => {
    const { formatMessage } = useFormat({ name: 'common' });
    const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
    const { formatMessage: formatValidationMessage } = useFormat({ name: 'validation' });
    const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
    const { processing, startProcessing, stopProcessing } = useProcessing();
    const { addressToAccountAddress } = useMappers();
    const { addShippingAddress, addBillingAddress, removeAddress, updateAddress, account, defaultBillingAddress } = useAccount();
    const { updateCart, hasShippingRequiredItem } = useCart();

    const [saveAsDefault, setSaveAsDefault] = useState(false);

    const { countries } = useCountries();
    const [states, setStates] = useState<States[]>();
    const [requiredState, setRequiredState] = useState(false);

    const initialAddressFormErrors = {};

    const [formErrors, setFormErrors] = useState<FieldErrors>(initialAddressFormErrors);
    const [isBlurred, setIsBlurred] = useState(false);
    const { loqateData, loqateDispatch } = useLoqate();
    const defaultStateOption = formatMessage({ id: 'select.state', defaultMessage: 'Select your state/province' });

    const accountBillingAddresses = useMemo(
        () => account?.addresses?.filter((address: Address) => address.isBillingAddress) ?? [],
        [account?.addresses]
    );

    const accountShippingAddresses = useMemo(
        () => account?.addresses?.filter((address: Address) => address.isShippingAddress) ?? [],
        [account?.addresses]
    );

    useEffect(() => {
        const isFirstBillingAddressToAdd = loqateData.addressType === 'billing' && accountBillingAddresses.length < 1;
        const isFirstShippingAddressToAdd = loqateData.addressType === 'shipping' && accountShippingAddresses.length < 1;

        if (isFirstBillingAddressToAdd || isFirstShippingAddressToAdd) {
            setSaveAsDefault(true);
        }
    }, [loqateData.addressType]);

    useEffect(() => {
        // if address is not empty object
        if (address && Object.keys(address).length > 0) {
            setFormErrors(initialAddressFormErrors);
            loqateDispatch({ type: 'UPDATE_LOQATE_FIELD', payload: { ...address } });
            loqateDispatch({ type: 'UPDATE_FIELD', payload: { name: 'firstName', value: address.firstName } });
            loqateDispatch({ type: 'UPDATE_FIELD', payload: { name: 'lastName', value: address.lastName } });
            loqateDispatch({ type: 'UPDATE_FIELD', payload: { name: 'phone', value: address.phone } });
            loqateDispatch({ type: 'UPDATE_FIELD', payload: { name: 'addressType', value: address.type } });
            loqateDispatch({ type: 'UPDATE_FIELD', payload: { name: 'country', value: address.country } });
            loqateDispatch({ type: 'UPDATE_FIELD', payload: { name: 'region', value: address.region } });
            setSaveAsDefault(address.isDefault ?? false);
        }
    }, [address, loqateDispatch]);

    const handleBlur = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            const fieldName: string = e.target.name;
            const error = validateAddressField(loqateData, fieldName);
            if (error) {
                setIsBlurred(true);
                setFormErrors(
                    prevErrors =>
                        ({
                            ...prevErrors,
                            ...error
                        }) as FieldErrors
                );
            } else {
                setFormErrors(initialAddressFormErrors);
            }
        },
        [loqateData]
    );

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            const target = e.target;

            loqateDispatch({ type: 'UPDATE_FIELD', payload: { name: target.name, value: target.value } });
        },
        [loqateDispatch, loqateData]
    );

    const handleControl = useCallback(
        (address: LoqateAddress) => {
            const nextState = {
                line1: address.Line1,
                line2: address.Line2,
                postalCode: address.PostalCode,
                city: address.City,
                country: address.CountryIso2,
                region: address.Province
            };

            loqateDispatch({ type: 'UPDATE_LOQATE_FIELD', payload: { ...nextState } });
            setFormErrors(initialAddressFormErrors);
        },
        [loqateDispatch]
    );

    const onPcaLoad = useCallback(
        (type: any, id: any, control: any) => {
            control.listen('populate', handleControl);
        },
        [handleControl]
    );

    const modalElement = useRef<HTMLDivElement>(null);
    const [isEventAdded, setIsEventAdded] = useState<boolean>(false);

    useEffect(() => {
        if (window?.pca && typeof window.pca === 'object') {
            if (!isEventAdded) {
                window?.pca.on('load', onPcaLoad);
                setIsEventAdded(true);
            }

            if (typeof window.pca?.load === 'function' && modalElement?.current) {
                window?.pca?.load();
            }

            if (address) {
                window?.pca.on('options', function (type: any, key: any, options: any) {
                    options.countries.value = address?.country;
                });
            }
        }
    }, [modalElement.current, isEventAdded, onPcaLoad]);

    useEffect(() => {
        const mappedStates = mapStates(loqateData.country, defaultStateOption);
        setStates(mappedStates.states);
        setRequiredState(mappedStates.required);
    }, [loqateData.country]);

    useEffect(() => {
        if (formErrors && !isBlurred) {
            scrollToError();
        }
    }, [formErrors, isBlurred]);

    const updateCartAddress = async (address: Address, addressType: CartAddressType) => {
        let cartUpdatePayload: CartDetails;

        if (addressType === CartAddressType.SHIPPING && hasShippingRequiredItem) {
            cartUpdatePayload = {
                shipping: address,
                skipTaxCalculation: true
            };
            setSameAsBillingAddress?.(false);
        } else {
            cartUpdatePayload = {
                billing: address,
                skipTaxCalculation: true
            };
        }
        await updateCart(cartUpdatePayload);
    };

    const handleAddAddressSubmit = useCallback(
        async (data: any, isDefault: boolean, addressToRemoveId?: string, isB2B?: boolean) => {
            const addressType = isB2B && defaultBillingAddress ? CartAddressType.SHIPPING : data.addressType;

            const result = await (addressType === CartAddressType.SHIPPING ? addShippingAddress : addBillingAddress)({
                ...addressToAccountAddress(data),
                isDefaultShippingAddress: addressType === CartAddressType.SHIPPING && isDefault,
                isDefaultBillingAddress:
                    isB2B && addressType === CartAddressType.BILLING ? true : addressType === CartAddressType.BILLING && isDefault
            });

            if ('errors' in result) {
                setFormErrors(result.errors as FieldErrors);
                stopProcessing();
            } else {
                if (addressToRemoveId) {
                    await removeAddress(addressToRemoveId);
                }
                if (result?.addresses?.length) {
                    const address = result.addresses[result.addresses.length - 1];
                    await updateCartAddress(address, addressType);
                }
                stopProcessing();
                closeModal();
                loqateDispatch({ type: 'RESET_FIELDS' });
                setSaveAsDefault(false);
                setFormErrors(initialAddressFormErrors);
            }
        },
        [saveAsDefault, hasShippingRequiredItem]
    );

    const handleEditAddressSubmit = useCallback(
        async (data: any, isDefault: boolean) => {
            const editedAddress = {
                ...addressToAccountAddress(data),
                addressId: address?.addressId,

                isDefaultShippingAddress: data.addressType === CartAddressType.SHIPPING && isDefault,
                isDefaultBillingAddress: data.addressType === CartAddressType.BILLING && isDefault
            };

            if (address?.addressId) {
                if (address.type !== data.addressType) {
                    await handleAddAddressSubmit(data, isDefault, address.addressId);
                } else {
                    const updateResult = await updateAddress(editedAddress);

                    if ('errors' in updateResult) {
                        setFormErrors(updateResult.errors as FieldErrors);
                    } else {
                        closeModal();
                        setFormErrors(initialAddressFormErrors);
                        loqateDispatch({ type: 'RESET_FIELDS' });
                        setSaveAsDefault(false);
                    }
                    stopProcessing();
                }
            }
        },
        [address, saveAsDefault]
    );

    const closeAddressModal = useCallback(() => {
        if (address) {
            loqateDispatch({ type: 'RESET_FIELDS' });
            setFormErrors(initialAddressFormErrors);
        }
        closeModal();
    }, [address, loqateDispatch, closeModal]);

    const handleSubmit = useCallback(async () => {
        startProcessing();
        if (address) {
            await handleEditAddressSubmit(loqateData, saveAsDefault);
        } else {
            await handleAddAddressSubmit(loqateData, saveAsDefault, undefined, account?.isB2B);
        }

        if (formErrors) {
            scrollToError();
        }
    }, [formErrors, loqateData, saveAsDefault, account?.isB2B]);

    const addressTypeOptions = useMemo(() => {
        const addressTypes = [
            {
                label: formatCheckoutMessage({ id: 'shippingAddress', defaultMessage: 'Shipping Address' }),
                value: 'shipping'
            }
        ];

        if (!account?.isB2B || !defaultBillingAddress) {
            addressTypes.splice(0, 0, {
                label: formatCheckoutMessage({ id: 'billingAddress', defaultMessage: 'Billing Address' }),
                value: 'billing'
            });
        }
        return addressTypes;
    }, [formatCheckoutMessage, account?.isB2B, defaultBillingAddress]);

    const fields = useCallback((): Fields[] => {
        return [
            {
                name: 'firstName',
                label: formatMessage({ id: 'firstName', defaultMessage: 'First Name' }),
                required: true,
                type: 'string',
                className: 'col-span-3',
                ...(formErrors?.firstName?.message && {
                    errorMessage: formatValidationMessage({
                        id: formErrors.firstName.message,
                        defaultMessage: 'Enter first name'
                    })
                })
            },
            {
                name: 'lastName',
                label: formatMessage({ id: 'lastName', defaultMessage: 'Last Name' }),
                required: true,
                type: 'string',
                className: 'col-span-3',
                ...(formErrors?.lastName?.message && {
                    errorMessage: formatValidationMessage({
                        id: formErrors.lastName.message,
                        defaultMessage: 'Enter last name'
                    })
                })
            },
            {
                name: 'line1',
                label: formatMessage({ id: 'address', defaultMessage: 'Address' }),
                labelDesc: '',
                maxLength: 60,
                required: true,
                type: 'string',
                className: 'col-span-3',
                ...(formErrors?.streetName?.message && {
                    errorMessage: formatValidationMessage({
                        id: formErrors.streetName.message,
                        defaultMessage: 'Enter your address'
                    })
                })
            },
            {
                name: 'line2',
                label: `${formatMessage({ id: 'address2', defaultMessage: 'Address 2' })}`,
                labelDesc: '',
                maxLength: 60,
                type: 'string',
                className: 'col-span-3',
                ...(formErrors?.additionalStreetInfo?.message && {
                    errorMessage: formatValidationMessage({
                        id: formErrors.additionalStreetInfo.message,
                        defaultMessage: 'This field must have a maximum 60 or less characters'
                    })
                })
            },
            {
                name: 'city',
                label: formatMessage({ id: 'city', defaultMessage: 'Town / City' }),
                labelDesc: '',
                maxLength: 30,
                required: true,
                type: 'string',
                className: 'col-span-3',
                ...(formErrors?.city?.message && {
                    errorMessage: formatValidationMessage({ id: formErrors.city.message, defaultMessage: 'Enter your town/city' })
                })
            },
            {
                name: 'postalCode',
                label: formatMessage({ id: 'zipCode', defaultMessage: 'Postcode' }),
                labelDesc: '',
                maxLength: 10,
                required: true,
                type: 'string',
                className: 'col-span-1',
                ...(formErrors?.postalCode?.message && {
                    errorMessage: formatValidationMessage({
                        id: formErrors.postalCode.message,
                        defaultMessage: 'Enter your postal code'
                    })
                })
            },
            {
                type: 'dropdown',
                name: 'country',
                items: countries,
                defaultValue: address?.country ?? 'GB',
                labelDesc: '',
                required: true,
                className: 'col-span-2',
                label: formatMessage({
                    id: 'country',
                    defaultMessage: 'Country'
                }),
                ...(formErrors?.country?.message && {
                    errorMessage: formatValidationMessage({ id: formErrors.country.message, defaultMessage: 'Enter your country' })
                })
            },
            {
                type: 'dropdown',
                name: 'region',
                items: states,
                defaultValue: address?.region ?? '',
                labelDesc: '',
                required: requiredState,
                className: 'col-span-3',
                label: formatMessage({
                    id: 'stateOrProvince',
                    defaultMessage: 'State/Province'
                }),
                ...(formErrors?.region?.message && {
                    errorMessage: formatValidationMessage({
                        id: formErrors.region.message,
                        defaultMessage: 'Select your state/province'
                    })
                })
            },
            {
                name: 'phone',
                label: `${formatMessage({ id: 'phoneNumber', defaultMessage: 'Phone Number *' })}`,
                maxLength: 22,
                required: true,
                type: 'phone',
                className: 'col-span-3',
                ...(formErrors?.phone?.message && {
                    errorMessage: formatValidationMessage({
                        id: formErrors?.phone?.message,
                        defaultMessage: 'Enter your phone number'
                    })
                })
            }
        ];
    }, [formatMessage, formatValidationMessage, formErrors, address?.country, states, requiredState]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeAddressModal}
            style={{ content: { background: 'transparent', border: 'none' } }}
            closeTimeoutMS={200}
            className="focus-visible:outline-none"
            preventScroll={true}
        >
            <div
                ref={modalElement}
                className="mx-auto max-h-screen w-[92%] max-w-[600px] overflow-auto rounded-md bg-white px-17 pb-70 pt-34 lg:px-30 lg:pb-32"
            >
                <h4 className="mt-20 text-20 lg:mt-0">
                    {address
                        ? formatAccountMessage({ id: 'address.edit', defaultMessage: 'Edit address' })
                        : formatAccountMessage({ id: 'address.add', defaultMessage: 'Add new address' })}
                </h4>
                <AddressForm
                    className="mt-18"
                    address={loqateData}
                    phone={(address?.phone ?? '') as PhoneNumberValue}
                    fields={fields}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onSubmit={handleSubmit}
                >
                    <div className="mt-12">
                        <Dropdown
                            name="addressType"
                            items={addressTypeOptions}
                            className="w-full border-input-border"
                            onChange={handleChange}
                            label={`${formatAccountMessage({
                                id: 'address.type',
                                defaultMessage: 'Address type'
                            })} *`}
                            value={loqateData?.addressType}
                        />
                    </div>
                    {((loqateData?.addressType === 'billing' && accountBillingAddresses.length > 0) ||
                        (loqateData?.addressType === 'shipping' && accountShippingAddresses.length > 0)) &&
                        !account?.isB2B && (
                            <div className="mt-16">
                                <Checkbox
                                    label={formatCheckoutMessage({
                                        id: 'address.setDefault',
                                        defaultMessage: 'Save as default address'
                                    })}
                                    labelPosition="on-right"
                                    checked={saveAsDefault}
                                    onChange={({ checked }) => setSaveAsDefault(checked)}
                                />
                            </div>
                        )}
                    <div className="mt-32 flex gap-12">
                        <Button variant="secondary" size="l" type="button" onClick={closeAddressModal}>
                            {formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
                        </Button>
                        <Button variant="primary" size="l" type="submit" loading={processing}>
                            {formatMessage({ id: 'save', defaultMessage: 'Save' })}
                        </Button>
                    </div>
                </AddressForm>
            </div>
        </Modal>
    );
};

export default AddressModal;
