import type { SDKResponse } from '@commercetools/frontend-sdk';
import type { CybersourceSettings } from '@wilm/common';
import useSWR from 'swr';
import { sdk } from 'sdk';

const useCybersourceSettings = () => {
    const response = useSWR<SDKResponse<CybersourceSettings>>('action/project/getCybersourceSettings', () =>
        sdk.callAction<CybersourceSettings>({ actionName: 'project/getCybersourceSettings' })
    );

    const data = (response.data?.isError ? ({} as CybersourceSettings) : response.data?.data)!;

    const cardinalOrigin = data?.cardinalOrigin ?? '';
    const tokenizeEarlyAndUseTokensOnly = data?.tokenizeEarlyAndUseTokensOnly ?? false;
    const earlyTokenizationExclusions = data?.earlyTokenizationExclusions ?? '';
    const flexFormLink = data?.flexFormLink ?? '';
    const useDynamicFlexFormLink = data?.useDynamicFlexFormLink ?? false;

    const cybersourceSettings: CybersourceSettings = {
        cardinalOrigin,
        tokenizeEarlyAndUseTokensOnly,
        earlyTokenizationExclusions,
        flexFormLink,
        useDynamicFlexFormLink
    };

    return cybersourceSettings;
};

export default useCybersourceSettings;
