import type { Account, Order, Payment } from '@wilm/common';
import { sdk } from 'sdk';

export interface AddInitialPaymentResultSuccess {
    isError: false;
    payment: Payment;
}
export interface AddInitialPaymentResultError {
    isError: true;
    errorMessage: string;
}

export const tokenizeUser = async (token: string, addressId: string, currencyCode: string) => {
    console.info('---> tokenizeUser token', token);

    const response = await sdk.callAction({
        actionName: `cart/tokenizeUser`,
        payload: {
            token,
            addressId,
            currencyCode
        }
    });
    const account = (response.isError ? null : response.data) as Account;

    return account;
};

export const addInitialPaymentToGetTokenContext = async (
    centAmount: number,
    currencyCode: string,
    paymentContainer?: 'cart',
    paymentInstalmentOption?: number
) => {
    const response = await sdk.callAction<Payment>({
        actionName: `cart/addPaymentToGetTokenContext`,
        payload: {
            currencyCode,
            centAmount,
            paymentContainer,
            paymentInstalmentOption
        }
    });

    const payment = response.isError ? null : response.data;

    if (payment) {
        if (!payment.signature) {
            return {
                isError: true,
                errorMessage: 'Error signature missing in payment'
            } as AddInitialPaymentResultError;
        }
        return {
            isError: false,
            payment
        } as AddInitialPaymentResultSuccess;
    } else {
        return {
            isError: true,
            errorMessage: 'Error creating payment'
        } as AddInitialPaymentResultError;
    }
};

export const failOrderById = async (orderId: string) => {
    const response = await sdk.callAction<Order>({
        actionName: `cart/failOrderById`,
        payload: {
            orderId
        }
    });

    return response;
};
